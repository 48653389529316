<template>
	<div>
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>
					<i class="el-icon-lx-cascades"></i> 礼物管理
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="handle-box" style="margin: 5px 0;">
			<el-button @click="addGift">新增</el-button>
		</div>
		<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header">
			<el-table-column prop="name" label="礼物名称" width="180" align="center"></el-table-column>
			<el-table-column prop="beans" label="福豆" width="180" align="center"></el-table-column>
			<el-table-column prop="sort" label="排序" width="180" align="center"></el-table-column>
			<el-table-column label="图片" align="center">
				<template #default="scope">
					<el-image style="width: 80px;" :src="filterCosImage(scope.row.icon)"></el-image>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="180" align="center">
				<template #default="scope">
					<el-button type="text" icon="el-icon-edit" @click="editGift(scope.row)">编辑</el-button>
					<el-button type="text" icon="el-icon-delete" @click="deleteGift(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination">
			<el-pagination background layout="total, prev, pager, next" :current-page="query.page"
				:page-size="query.limit" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
		</div>

		<el-dialog title="编辑/新增礼物" v-model="addVisible" width="30%">
			<el-form ref="form" :model="add_query" label-width="80px">
				<el-form-item label="礼物名称">
					<el-input v-model="add_query.name"></el-input>
				</el-form-item>
				<el-form-item label="排序">
					<el-input v-model="add_query.sort" type="number"></el-input>
				</el-form-item>
				<el-form-item label="礼物图片">
					<input type="file" @change="chooseimage" id="image-input" accept="image/jpeg ,image/png"
						style="display: none;" :value="inputVal" />
					<div class="image-box" v-if="add_query.icon" @click="uploadClick">
						<el-image fit="cover" class="img" :src="filterCosImage(add_query.icon)"></el-image>
					</div>
					<div class="upload-btn" @click="uploadClick" v-if="!add_query.icon">添加</div>
				</el-form-item>
				<el-form-item label="所需福豆">
					<el-input v-model="add_query.beans"></el-input>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="addVisible = false">取 消</el-button>
					<el-button type="primary" @click="save">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import PAGE_CONFIG from '@/utils/config'
	import cosUpload  from "@/utils/qcloud";
	import { filterCosImage } from '@/utils/common.js'
	export default {
		data() {
			return {
				tableData: [],
				query: {
					page: 1,
					limit: 15,
					title: '',
					sort: 0
				},
				pageTotal: 0,
				defaultUrl: PAGE_CONFIG.defaultUrl,
				addVisible: false,
				add_query: {
					name: '',
					icon: '',
					beans: '',
					sort: 0
				},
				inputVal: '',
			}
		},
		watch: {
			addVisible(val) {
				if (!val) {
					this.add_query = {
						name: '',
						icon: '',
						beans: '',
						sort: 0
					}
				}
			}
		},
		created() {
			this.getList()
		},
		methods: {
			filterCosImage,
			handlePageChange(val) {
				this.query.page = val
				this.getList();
			},
			getList() {
				this.$axios({
					url: '/admin/gift/getList',
					method: 'get',
					params: {
						...this.query
					}
				}).then((res) => {
					if (res.code == 200) {
						this.tableData = res.data.items
						this.pageTotal = res.data.total
					}
				}).catch(err => {
					console.log(err)
				});
			},
			editGift(item) {
				this.add_query = {
					id: item.id,
					name: item.name,
					icon: item.icon,
					beans: item.beans,
					sort: item.sort
				}
				this.addVisible = true
			},
			addGift() {
				this.addVisible = true
			},
			deleteGift(item) {
				this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.delItem(item)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			delItem(item) {
				this.$axios({
					url: '/admin/gift/delete',
					method: 'delete',
					params: {
						id: item.id
					}
				}).then(({
					code
				}) => {
					if (code == 200) {
						this.getList()
						this.$message.success("操作成功")
					}
				}).catch(err => {
					console.log(err)
				});
			},
			save() {
				this.addVisible = true
				let url;
				if(this.add_query.id) {
					url = '/admin/gift/edit'
				}else {
					url = '/admin/gift/add'
				}
				this.$axios({
					url,
					method: 'post',
					data: {
						...this.add_query
					}
				}).then((res) => {
					if (res.code == 200) {
						this.addVisible = false
						this.getList()
					}
				}).catch(err => {
					console.log(err)
				});
			},
			uploadClick() {
				document.getElementById('image-input').click();
			},
			chooseimage(e) {
				this.uploadFile(e.target.files);
			},
			async uploadFile(files) {
				var file = files[0];
				let cosData = await cosUpload.cosUpLoadOneFile(file)
				if(cosData.statusCode === 200 ) {
					this.add_query.icon = cosData.Location
					this.inputVal = ""
				} else {
					this.inputVal = ""
					this.$message.error('文件上传失败')
				}
			}
		}
	}
</script>

<style scoped>

</style>

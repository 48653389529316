import request from "@/utils/request";
import store from '@/store/index.js'
import COS from 'cos-js-sdk-v5';
var cosCommonUrl = 'cos/xtadmin/image/'
var uuid = function () {
	return 'xxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
};
var formatDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = ('0'+(date.getMonth() + 1)).slice(-2);
    const day = ('0'+(date.getDate())).slice(-2);
	return `${year}${month}${day}/`;
} 
var randomFileName = function (filename) {
	let fileNameArr = filename.split('.');
	return cosCommonUrl+formatDate()+uuid() + '.' + fileNameArr[fileNameArr.length - 1];
}
var cos = new COS({
	// ForcePathStyle: true, // 如果使用了很多存储桶，可以通过打开后缀式，减少配置白名单域名数量，请求时会用地域域名
	getAuthorization:  (options, callback)=> {
		// 初始化时不会调用，只有调用 cos 方法（例如 cos.putObject）时才会进入
		// 异步获取临时密钥
		request.get('admin/getCosConfig').then( (res)=>{
			if(res.code == 200) {
				console.log(res.data,1111)
				var credentials = res.data && res.data.credentials;
				if (!res.data || !credentials) return console.error('credentials invalid');
				callback({
					TmpSecretId: credentials.tmpSecretId,
					TmpSecretKey: credentials.tmpSecretKey,
					// v1.2.0之前版本的 SDK 使用 XCosSecurityToken 而不是 SecurityToken
					SecurityToken: credentials.sessionToken,
					// 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
					StartTime: res.data.startTime, // 时间戳，单位秒，如：1580000000
					ExpiredTime: res.data.expiredTime, // 时间戳，单位秒，如：1580000900
				});
			}
		})
	}
});
// 单文件上传
const cosUpLoadOneFile = (file)=> {
	console.log(file,444444444444)
	return new Promise(function(resolve) {
		cos.uploadFile({
			Bucket:store.state.Bucket,/* 填入您自己的存储桶，必须字段 */
			Region:store.state.Region, /* 存储桶所在地域，例如ap-beijing，必须字段 */
			Key: randomFileName(file.name),  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
			Body: file, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
			SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */
			onTaskReady: function(taskId) {                   /* 非必须 */
				console.log(taskId);
			},
			onProgress: function (progressData) {           /* 非必须 */
				console.log(JSON.stringify(progressData));
			},
			
		}, function(err, data) {
			console.log(err,444444444444)
			if(err) {
                resolve(false);
            } else {
				data.Location = 'https://'+data.Location
				console.log(data)
                resolve(data);
            }
		});
		
	})
}

// 多文件cos上传
const cosUploadFile =  (events)=> {
    const files = events.currentTarget.files;
	console.log(events)
    const uploadFileList = [...files].map((file) => {
        return {
            Bucket:store.state.Bucket,
            Region:store.state.Region,
            Key: randomFileName(file.name),
            Body: file,
            onTaskReady: function(taskId) {
                /* taskId可通过队列操作来取消上传cos.cancelTask(taskId)、停止上传cos.pauseTask(taskId)、重新开始上传cos.restartTask(taskId) */
                console.log(taskId);
            }
        }
    });
    console.log(files)
    console.log(uploadFileList)
	return new Promise(function (resolve) {
		cos.uploadFiles({
            files: uploadFileList,
			SliceSize: 1024 * 1024 * 10, ///* 设置大于10MB采用分块上传 */
			onProgress: function (info) {
				console.log(info);
				// progress ? progress(info.total, info.speed, info.percent) : '';
			},
			onFileFinish: function () {
				// fileFinish ? fileFinish(err || data, options) : '';
			},
		}, function (err, data) {
			//data 格式为  { files:[{data:{}}, {}, ...]}
			console.log(err,444444444444)
            console.log(data)
            if(err) {
                resolve(false);
            } else {
				data.files.forEach((item)=> {
					if(item.data.statusCode === 200) {
						item.data.Location='https://'+item.data.Location
					}
				})
                resolve(data.files);
            }
		});
	});
};


export default {
    cosUploadFile,
	cosUpLoadOneFile
}

